import React, { useState } from 'react';

import { IAccountV2, ICartV2 } from 'src/explore/types/shoppe';
import { LogIn } from './elements/Login';

import styles from './index.module.sass';
import StripePaymentButton from './compositions/StripePaymentButton';

interface ExpressCheckoutProps {
  cart: ICartV2;
  account: IAccountV2;
}

const ExpressCheckout: React.FC<ExpressCheckoutProps> = ({ cart, account }) => {
  const [ showStripeButton, setShowStripeButton ] = useState( true );

  if ( account && !showStripeButton ) return null;

  return (
    <div className={styles.container}>
      <div className={styles.title}>Express Checkout</div>
      <div className={styles.buttonsContainer}>
        {!account && (
          <div className={`${styles.buttonContainer} bs4`}>
            <LogIn />
          </div>
        )}

        {showStripeButton && (
          <div className={styles.buttonContainer}>
            <StripePaymentButton cart={cart} setVisible={setShowStripeButton} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpressCheckout;
