import { StripePaymentRequest } from '../types';

export function getPaymentMethod( paymentRequest: StripePaymentRequest ): string {
  if ( !paymentRequest._activeBackingLibraryName ) return 'unknown';
  if ( paymentRequest._activeBackingLibraryName.toLowerCase().includes( 'apple' )) return 'apple';
  if ( paymentRequest._activeBackingLibraryName.toLowerCase().includes( 'google' )) return 'google';
  if ( paymentRequest._activeBackingLibraryName.toLowerCase().includes( 'link' )) return 'link';

  return paymentRequest._activeBackingLibraryName;
}
