import React, { useCallback, useEffect, useState } from 'react';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';

import api from 'src/explore/services/api';
import { useRoutes } from 'src/explore/hooks/useRoutes';
import { useShopConfig } from 'src/explore/hooks/useShopConfig';
import analytics from 'src/explore/services/analytics';
import { isDarkMode } from 'src/explore/services/theme';
import { ICartV2AggregatedTotals } from 'src/explore/types/shoppe';

import camelcaseKeys from 'camelcase-keys';
import { buildLineItems, getDateStringFromId, createShippingOptionsList } from './services';
import { DeliveryOption, IDisplayItem, IPaymentRequestButton, IShippingOption } from './types';
import { getPaymentMethod } from '../../services';

let latestDisplayItems: IDisplayItem[] = [];

interface DeliveryOptionResponse {
  data: DeliveryOption[];
}

export const PaymentRequestButton = ({
  cart,
  subOrderId,
  paymentRequest,
  setDeliveryDate,
}: IPaymentRequestButton ) => {
  const [ shippingPrice, setShippingPrice ] = useState<number>( null );
  const [ shippingTotal, setShippingTotal ] = useState<number>( null );
  const routes = useRoutes();
  const config = useShopConfig();

  const getTotalWithShipping = useCallback(
    async ( shippingOption: IShippingOption ) => {
      const totalObject = ( receivedTotal: number, aggregatedTotals: ICartV2AggregatedTotals ) => {
        const total = receivedTotal && receivedTotal > 0 ? receivedTotal : 0;
        const displayItems = buildLineItems( aggregatedTotals );
        if ( displayItems?.length > 0 ) {
          latestDisplayItems = displayItems;
        }
        setShippingTotal( total );
        return {
          status: 'success',
          total: {
            label: 'Total',
            amount: total,
          },
          displayItems: latestDisplayItems,
        };
      };

      if ( shippingOption.amount === shippingPrice ) {
        return totalObject( shippingTotal, null );
      }
      setShippingPrice( shippingOption.amount );

      try {
        const params = {
          requested_delivery_on: getDateStringFromId( shippingOption.id ),
        };
        const baseURL = config?.isWhitelabel ? '/api' : '/api/v4';
        const response = await api.patch(
          routes.api.checkout.express.deliveryDate( subOrderId ),
          params,
          {
            baseURL,
          }
        );

        const { totalInCents, aggregatedTotals } = camelcaseKeys( response.data?.data, {
          deep: true,
        });

        return totalObject( totalInCents, aggregatedTotals );
      } catch ( e ) {
        return totalObject( cart.totalInCents + shippingOption.amount, null );
      }
    },
    [ cart.totalInCents, routes.api.checkout.express, shippingPrice, shippingTotal, subOrderId ]
  );

  const handleShippingAddressChange = useCallback(
    async ( ev: any ) => {
      if ( !( ev.shippingAddress.country === 'US' || ev.shippingAddress.country === 'CA' )) {
        ev.updateWith({ status: 'invalid_shipping_address' });
      } else {
        try {
          const params = {
            delivery_country: ev.shippingAddress.country,
            delivery_city: ev.shippingAddress.city,
            delivery_state: ev.shippingAddress.region,
            delivery_postal_code: ev.shippingAddress.postalCode,
          };

          const baseURL = config?.isWhitelabel ? '/api' : '/api/v4';
          const response = await api.patch<DeliveryOptionResponse>(
            routes.api.checkout.express.deliveryOptions( subOrderId ),
            params,
            {
              baseURL,
            }
          );
          const shippingOptions = createShippingOptionsList( response.data?.data );

          if ( shippingOptions.length === 0 ) {
            ev.updateWith({ status: 'invalid_shipping_address' });
            return;
          }

          const updateWithTotal = await getTotalWithShipping( shippingOptions[0]);
          setDeliveryDate( getDateStringFromId( shippingOptions[0].id ));
          const updateWithShippingAndTotal = Object.assign( updateWithTotal, { shippingOptions });
          ev.updateWith( updateWithShippingAndTotal );
        } catch ( error ) {
          ev.updateWith({ status: 'fail' });
        }
      }
    },
    [ getTotalWithShipping, routes.api.checkout.express, setDeliveryDate, subOrderId ]
  );

  const handleShippingOptionChange = useCallback(
    async ( ev: any ) => {
      if ( !ev.shippingOption ) {
        ev.updateWith({ status: 'fail' });
        return;
      }
      setDeliveryDate( getDateStringFromId( ev.shippingOption.id ));
      const updateWithTotal = await getTotalWithShipping( ev.shippingOption );
      ev.updateWith( updateWithTotal );
    },
    [ getTotalWithShipping, setDeliveryDate ]
  );

  useEffect(() => {
    if ( paymentRequest ) {
      paymentRequest.on( 'shippingaddresschange', handleShippingAddressChange );
      paymentRequest.on( 'shippingoptionchange', handleShippingOptionChange );
    }

    return () => {
      if ( paymentRequest ) {
        paymentRequest.off( 'shippingaddresschange' );
        paymentRequest.off( 'shippingoptionchange' );
      }
    };
  }, [
    paymentRequest,
    handleShippingAddressChange,
    handleShippingOptionChange,
    getTotalWithShipping,
    setDeliveryDate,
    getTotalWithShipping,
    routes.api.checkout.express,
    subOrderId,
  ]);

  const handleClick = () => {
    analytics.trackEvent( 'Checkout Start Viewed', {
      checkout_type: 'express',
      checkout_version: '2.0.1',
    });
    analytics.trackClick({
      name: 'payment-request-button',
      section: 'express-checkout',
      payment_service_provider: getPaymentMethod( paymentRequest ),
    });
  };

  return (
    <>
      {paymentRequest && (
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                height: '48px', // Defaults to '40px'. The width is always '100%'.
                theme: isDarkMode() ? 'light' : 'dark',
              },
            },
          }}
          onClick={handleClick}
        />
      )}
    </>
  );
};

export default PaymentRequestButton;
