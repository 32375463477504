import { formatPrice, formatDate } from 'src/explore/services/FormatDelivery';
import { ICartV2AggregatedTotals } from 'src/explore/types/shoppe';
import { DeliveryOption, IDisplayItem } from '../types';

export function buildLineItems( aggregatedTotals: ICartV2AggregatedTotals ): IDisplayItem[] {
  const lineItems = [];

  if ( aggregatedTotals ) {
    const {
      appliedAccountCreditsInCents,
      cartItemsSubtotalInCents,
      giftCardDiscountInCents,
      importShippingSurchargeSubtotalInCents,
      promotionDiscountInCents,
      shippingSubtotalInCents,
      taxesTotalInCents,
    } = aggregatedTotals;

    if ( cartItemsSubtotalInCents ) {
      lineItems.push({
        amount: cartItemsSubtotalInCents,
        label: 'Subtotal',
      });
    }

    if ( shippingSubtotalInCents ) {
      lineItems.push({
        amount: shippingSubtotalInCents,
        label: 'Shipping',
      });
    }


    if ( taxesTotalInCents ) {
      lineItems.push({
        amount: taxesTotalInCents,
        label: 'Taxes',
      });
    }

    if ( promotionDiscountInCents ) {
      lineItems.push({
        amount: -1 * promotionDiscountInCents,
        label: 'Promotions',
      });
    }

    if ( giftCardDiscountInCents ) {
      lineItems.push({
        amount: -1 * giftCardDiscountInCents,
        label: 'Gift Cards',
      });
    }

    if ( importShippingSurchargeSubtotalInCents ) {
      lineItems.push({
        amount: importShippingSurchargeSubtotalInCents,
        label: 'Shipping Surcharge',
      });
    }

    if ( appliedAccountCreditsInCents && appliedAccountCreditsInCents > 0 ) {
      lineItems.push({
        amount: -1 * appliedAccountCreditsInCents,
        label: 'Account Credits',
      });
    }
  }
  return lineItems;
}

export function uniqueEnoughId( id: string ): string {
  return `${id}|${new Date().getTime()}`;
}

export function getDateStringFromId( id: string ): string {
  return id.split( '|' )[0];
}

export function createShippingOptionsList( shippingOptionsRaw: DeliveryOption[]): any {
  if ( !shippingOptionsRaw || shippingOptionsRaw.length === 0 ) {
    return [];
  }

  let index = 0;
  let fastestCheapest = 0;
  let minPrice = -1;
  const shippingOptions = shippingOptionsRaw
    .filter(( option ) => !option.sold_out )
    .map(( option ) => {
      if ( option.rate_in_cents < minPrice || index === 0 ) {
        minPrice = option.rate_in_cents;
        fastestCheapest = index;
      }
      index += 1;

      const label = option.label.includes( 'Standard Shipping' )
        ? option.label
        : `Delivers ${formatDate( option.estimated_delivery_date )}`;

      return {
        id: uniqueEnoughId( option.estimated_delivery_date ),
        label,
        detail: `${formatPrice( option.rate_in_cents )} Shipping`,
        amount: option.rate_in_cents,
      };
    });

  if ( shippingOptions.length === 0 ) {
    return [];
  }

  const temp = shippingOptions.splice( fastestCheapest, 1 );
  shippingOptions.unshift( temp[0]);

  return shippingOptions;
}
