import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@goldbely/explore-component-library';

import { open, switchMode } from 'src/explore/compositions/Auth/actions';
import { SvgUse } from 'src/shoppe/elements/SvgUse';

import styles from './index.module.sass';

export const LogIn: React.FC = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch( switchMode( 'login' ));
    dispatch( open());
  };

  return (
    <>
      <Button className={styles.button} onClick={handleClick}>
        <span className={styles.text}>Pay with</span>
        <SvgUse aria-label="Goldbelly" name="goldbelly-stacked" className={styles.logo} />
      </Button>
    </>
  );
};
