import React, { useState, useEffect, useCallback } from 'react';
import camelCaseKeys from 'camelcase-keys';
import { useStripe, useElements } from '@stripe/react-stripe-js';

import api from 'src/explore/services/api';
import analytics from 'src/explore/services/analytics';
import { useRoutes } from 'src/explore/hooks/useRoutes';
import { useShopConfig } from 'src/explore/hooks/useShopConfig';
import { ICartV2 } from 'src/explore/types/shoppe';

import { StripePaymentRequest, StripePaymentResponse } from './types';
import PaymentRequestButton from './compositions/PaymentRequestButton';
import { getPaymentMethod } from './services';
import { Skeleton } from '../../elements/Skeleton';

interface ExpressCheckoutProps {
  cart: ICartV2;
  setVisible: ( visible: boolean ) => void;
}

const ExpressCheckout: React.FC<ExpressCheckoutProps> = ({ cart, setVisible }) => {
  const subcarts = camelCaseKeys( cart?.subcarts, { deep: true });
  const elements = useElements();
  const stripe = useStripe();
  const routes = useRoutes();
  const config = useShopConfig();

  const [ paymentRequest, setPaymentRequest ] = useState( null );
  const [ deliveryDate, setDeliveryDate ] = useState( null );

  useEffect(() => {
    if ( !stripe || !elements || cart.totalInCents < 0 || !setVisible ) return;

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Total',
        amount: cart.totalInCents,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
      requestShipping: true,
    });

    const trackCheckoutEvent = ( options: string[]) => {
      analytics.trackEvent( 'Express Checkout Visible', {
        payment_options_shown: options,
      });
    };

    pr.canMakePayment().then(( result ) => {
      if ( subcarts.length !== 1 || !result ) {
        setVisible( false );
        trackCheckoutEvent([ 'regular' ]);
      } else {
        setPaymentRequest( pr );
        setVisible( true );
        trackCheckoutEvent([ 'regular', getPaymentMethod( pr as StripePaymentRequest | any ) ]);
      }
    });
  }, [ stripe, elements, cart.totalInCents, setVisible, subcarts.length ]);

  const handlePaymentMethod = useCallback(
    async ( ev: StripePaymentResponse ) => {
      const handleConfirmOrderResponse = async ( response: any, complete: any ) => {
        try {
          if ( response.error ) {
            complete( 'fail' );
          } else if ( response.requires_action ) {
            complete( 'success' );
            const { error } = await stripe.handleCardAction( response.payment_intent_client_secret );
            if ( error ) {
              complete( 'fail' );
            } else {
              // eslint-disable-next-line no-use-before-define
              sendConfirmationRequest();
            }
          } else {
            complete( 'success' );
            window.location.href = '/success';
          }
        } catch ( error ) {
          console.error( error );
          complete( 'fail' );
        }
      };

      const sendConfirmationRequest = async () => {
        try {
          if ( subcarts.length !== 1 || !deliveryDate ) {
            ev.complete( 'fail' );
            return;
          }
          const params = {
            delivery_city: ev.shippingAddress.city,
            delivery_email: ev.payerEmail,
            delivery_phone: ev.payerPhone,
            delivery_postal_code: ev.shippingAddress.postalCode,
            delivery_state_abbr: ev.shippingAddress.region,
            delivery_street1: ev.shippingAddress.addressLine[0],
            delivery_street2: ev.shippingAddress.addressLine[1] ?? '',
            name: ev.payerName,
            payment_method_id: ev.paymentMethod.id,
            payment_service_provider: getPaymentMethod( paymentRequest ),
            requested_delivery_on: deliveryDate,
            suborder_id: subcarts[0].id,
          };

          const baseURL = config?.isWhitelabel ? '/api' : '/api/v4';
          const response = await api.post<any>( routes.api.checkout.express.confirmation(), params, {
            baseURL,
          });
          handleConfirmOrderResponse( response, ev.complete );
        } catch ( error ) {
          ev.complete( 'fail' );
          console.error( 'error', error );
        }
      };

      sendConfirmationRequest();
    },
    [ stripe, subcarts, deliveryDate, paymentRequest, routes.api.checkout.express ]
  );

  useEffect(() => {
    // When payment method is selected and the user has selected to pay in the google or apple interface
    if ( paymentRequest ) paymentRequest.on( 'paymentmethod', handlePaymentMethod );

    return () => {
      if ( paymentRequest ) paymentRequest.off( 'paymentmethod' );
    };
  }, [ paymentRequest, handlePaymentMethod ]);

  if ( !cart || subcarts.length !== 1 || !stripe || !elements ) return <></>;

  return (
    <>
      {paymentRequest ? (
        <PaymentRequestButton
          cart={cart}
          subOrderId={subcarts[0].id}
          paymentRequest={paymentRequest}
          setDeliveryDate={setDeliveryDate}
        />
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default ExpressCheckout;
