const DAY_NAMES = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
export function formatDate( dateString: string, _invalidString?: string ): string {
  const date = new Date( `${dateString}T00:00:00` );
  if ( Number.isNaN( date.getTime())) {
    return _invalidString ?? dateString;
  }
  const day = date.getDate();
  const month = date.toLocaleString( 'default', { month: 'short' });
  return `${DAY_NAMES[date.getDay()]}, ${month} ${day}`;
}

export function formatPrice(
  priceInCents: number,
  zeroCost: string = 'Free',
  minFractionDigits: number = 0
): string {
  if ( Number.isNaN( priceInCents % 100 )) return '$--';
  if ( priceInCents === 0 ) return zeroCost;
  const hasCents = priceInCents % 100 !== 0;
  return ( priceInCents / 100 ).toLocaleString( 'en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: hasCents ? 2 : minFractionDigits,
    minimumFractionDigits: hasCents ? 2 : minFractionDigits,
  });
}
